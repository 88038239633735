<template>
  <div class="dashboard">
    <div class="row dashboard__top_panel">
      <div class="col-9 px-0 d-flex align-items-center dashboard--large">
        <k-dropdown-native
          class="dashboard__dropdown dashboard--large--bold"
          :data-items="availableYear"
          :value="choosenYear"
          @change="changeYear"
        >
        </k-dropdown-native>
        <span class="ps-2">{{
          ticker.isin === 'missing' ? 'N/A' : ticker.isin
        }}</span
        ><span class="dashboard--large--bold ps-2">{{ ticker.name }}</span
        ><b class="ps-2">{{ ticker.status }}</b>
      </div>
      <div class="col-3 d-flex justify-content-end align-items-center">
        <k-button
          :disabled="!ticker.id"
          @click="showWatchlistDialog"
          class="custom-button-icon me-2"
          theme-color="secondary"
          :size="'small'"
          fill-mode="solid"
          rounded="full"
        >
          <span class="k-icon k-i-star-outline"></span>
        </k-button>
        <k-button theme-color="primary" @click="createReport"
          >Full Report</k-button
        >
      </div>
    </div>
    <div class="row">
      <div
        class="col-8 custom-scrollbar pe-0"
        style="height: calc(100vh - 85px); overflow-x: hidden"
      >
        <div class="row">
          <ticker-info
            :ticker="ticker"
            :popups-map="popupsMap"
            :isReportFlag="false"
          />
        </div>
        <div class="row dashboard__box-warning" v-if="ticker.warning != null">
          <div class="d-flex justify-content-between dashboard__warning">
            <div class="row">
              <div class="warning-icon"><icon-warning></icon-warning></div>
              <div class="warning-message" v-html="ticker.warning"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <history-chart
            :data.sync="historyChart"
            :name.sync="ticker.name"
            :popups-map="popupsMap"
            :isReportFlag="false"
            :riskHistoryData="riskHistoryData"
          />
        </div>
        <div class="row d-flex justify-content-between">
          <div class="col-6 pe-0">
            <distribution-chart-vue
              :chart.sync="distSectorRisk"
              :isSectorRisk="true"
              :popups-map="popupsMap"
              :isReportFlag="false"
            />
          </div>
          <div class="col-6 ps-0">
            <distribution-chart-vue
              :chart.sync="distRisk"
              :isSectorRisk="false"
              :popups-map="popupsMap"
              :isReportFlag="false"
            />
          </div>
        </div>
      </div>
      <div class="col-4 ps-0">
        <div class="row dashboard--radial-chart mb-2">
          <radial-chart
            :chartValue="radialDetail"
            :score="ticker.finalScore"
            :tickerId="ticker.id"
            :year="choosenYear"
            :isReportFlag="false"
          ></radial-chart>
        </div>
        <div class="row">
          <chart-detail :radialDetail="chartDetail" :isReportFlag="false" />
        </div>
      </div>
    </div>
    <k-dialog
      v-if="restrictAccess"
      :title="' '"
      @close="restrictAccess = false"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="fw-bold text-center">
            You don't currently have permission to access this
            {{ selectedExchange }} Exchange country in the Saved Filter. The
            filter will not be applied.
          </div>
          <div class="fw-bold text-center">
            Click on Cancel to dismiss this message
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="restrictAccess = false"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="reportRestrictAccess"
      :title="' '"
      @close="reportRestrictAccess = false"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="fw-bold text-center">
            There isn't a report for this year because the available data is
            inconclusive.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="reportRestrictAccess = false"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
  </div>
</template>
<script>
import { EventBus } from '../../main.js';
import axios from 'axios';
import radialChart from '../dashboard/radialChart.vue';
import tickerInfo from '../dashboard/tickerInfo.vue';
import historyChart from '../dashboard/historyChart.vue';
import distributionChartVue from '../dashboard/chartDashboard.vue';
import chartDetail from '../dashboard/chartDetail.vue';
import IconWarning from '../icons/IconWarning.vue';

export default {
  components: {
    radialChart,
    tickerInfo,
    historyChart,
    distributionChartVue,
    chartDetail,
    IconWarning
  },
  data() {
    return {
      availableYear: [],
      choosenYear: 0,
      valueAxis: [
        {
          line: {
            visible: false
          }
        }
      ],
      risk: [],
      ticker: {
        id: '',
        name: '',
        status: '',
        mv: null,
        auditor: '',
        dlOptimistic: null,
        dlPessimistic: null,
        domicileCountry: '',
        equityLoss: null,
        exchangeCountry: '',
        finalScore: null,
        finalScoreNotRounded: null,
        incorpCountry: '',
        industryGroup: '',
        rankPercent: 0,
        rfpAbsolute: null,
        rfpRelative: null,
        sector_name: '',
        telOptimistic: null,
        telPessimistic: null,
        exchange_country: '',
        region_name: '',
        isin: '',
        warning: null,
        rating: null
      },

      sectorRiskChart: {
        x: [],
        y: [],
        notes: ''
      },

      riskChart: {
        x: [],
        y: [],
        notes: ''
      },

      historyChart: {
        score: [],
        average: [],
        year: []
      },
      riskHistoryData: {},

      chartDetail: [],

      radialDetail: [],

      popupsMap: {},

      reportValue: {
        chartDetail: [],
        choosenYear: 0,
        ticker: {},
        riskChart: {},
        sectorRiskChart: {},
        historyChart: {},
        radialDetail: {},
        sectorRisk: 0,
        riskPoint: 0,
        categorySector: [],
        category: []
      },

      distributionPoint: 0,
      distributionPointSector: 0,
      distributionCategory: [],
      distributionCategorySector: [],

      // dist: {
      // 	year: 0,
      // 	ticker: null,
      // 	riskChart: null,
      // 	sectorRiskChart: null,
      // 	point: 0,
      // 	pointSector: 0,
      // 	category: null,
      // 	categorySector: null
      // },

      distSectorRisk: {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      },
      distRisk: {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      },
      restrictAccess: false,
      reportRestrictAccess: false,
      selectedExchange: null
    };
  },
  mounted() {
    EventBus.$on('ticker', (ticker) => {
      if (!this.$store.state.isShareUrl) {
        this.ticker.id = ticker.id;
        this.ticker.exchange_countryID = ticker.exchange;
        this.ticker.year = ticker.year;
        this.selectedExchange = ticker.selectedExchange;
        this.getAvailableYear(ticker.id, ticker.exchange, ticker.year);
        this.getRiskScoreHistory(ticker.id, ticker.exchange);
      }
    });

    if (this.$store.state.isShareUrl) {
      this.selectedExchange = this.$store.state.shareRegionName;
      this.getAvailableYear(
        this.$store.state.shareTicker,
        this.$store.state.shareRegion,
        this.$store.state.shareYear
      );
      this.getRiskScoreHistory(
        this.$store.state.shareTicker,
        this.$store.state.shareRegion
      );
    }

    this.getPopupsMap();
  },
  destroyed() {
    EventBus.$off('ticker');
  },
  methods: {
    async getPopupsMap() {
      if (this.$store.getters.getPopups.length < 1) {
        axios.post('/dashboard/popup').then((response) => {
          this.$store.commit('fillPopups', response.data);
          this.popupsMap = this.$store.getters.getPopupsMap;
        });
      } else {
        this.popupsMap = this.$store.getters.getPopupsMap;
      }
    },
    changeYear(event) {
      this.choosenYear = event.value;
      this.$store.commit('fillShareYear', this.choosenYear);
      if (this.ticker.id.length < 1)
        this.ticker.id = this.$store.state.shareTicker;
      this.getTickerDetail(
        this.ticker.id,
        this.choosenYear,
        this.ticker.exchange_countryID
      );
      this.getChartDetail(
        this.ticker.id,
        this.choosenYear,
        this.ticker.exchange_countryID
      );
    },

    getAvailableYear(tickerId, exchange_countryID, year) {
      axios
        .post('/dashboard/availableYears', {
          tickerId,
          exchangeCountryId: exchange_countryID
        })
        .then((response) => {
          this.availableYear = response.data.data;
          if (response.data.restrictAccess == true) {
            this.restrictAccess = true;
            this.clearData();
            return;
          }
          if (this.availableYear.length > 0) {
            this.choosenYear = year == null ? this.availableYear[0] : year;
          } else {
            this.choosenYear = null;
          }

          this.getTickerDetail(tickerId, this.choosenYear, exchange_countryID);
          this.getChartDetail(tickerId, this.choosenYear, exchange_countryID);

          this.$store.commit('fillShareYear', this.choosenYear);
        });
    },

    async getChartDetail(tickerId, year, exchange_countryID) {
      if (!tickerId || !year || !exchange_countryID) {
        this.chartDetail = [];
        this.radialDetail = [];
        return;
      }
      axios
        .post('/dashboard/chartDetail', {
          tickerId,
          year,
          exchangeCountryId: exchange_countryID
        })
        .then((response) => {
          // this.chartDetail will be used by Chart Detail below Radial Chart
          this.chartDetail = [...response.data];
          this.radialDetail = [];

          // this.radialDetail will be used by Radial Chart, sort by cluster type
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'ac';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'wo';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'va';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'sm';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'ma';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'iv';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'ic';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'gr';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'ge';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'cr';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'go';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'ca';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 're';
            })[0]
          );
          this.radialDetail.push(
            this.chartDetail.filter(function (detail) {
              return detail.cluster_type === 'as';
            })[0]
          );
        });
    },

    async getRiskDistribution(year, exchange_countryID) {
      if (!year || !exchange_countryID) {
        this.riskChart.x = [];
        this.riskChart.y = [];
        this.distRisk = {
          year: 0,
          ticker: {},
          chart: {},
          point: 0,
          category: []
        };
        return;
      }

      this.$nextTick(() => {
        axios
          .post('/dashboard/riskDistribution', {
            year,
            exchangeCountryId: exchange_countryID
          })
          .then(async (response) => {
            let data = response.data || [];
            this.riskChart.x = [];
            this.riskChart.y = [];
            for (let i = 0; i < data.length; i++) {
              this.riskChart.x.push(data[i].x);
              this.riskChart.y.push(data[i].y);
            }

            this.distRisk.chart = this.riskChart;

            this.distributionPoint =
              this.ticker.finalScoreNotRounded == null
                ? -1
                : data.length * this.ticker.finalScoreNotRounded;

            this.distRisk.point = this.distributionPoint;

            this.distributionCategory = [];
            const len = Math.round(data.length);

            for (let x = 0; x <= len; x++) {
              if (x == 0) this.distributionCategory.push('0.0');
              else if (x <= Math.round(len * 0.2))
                this.distributionCategory.push('0.2');
              else if (x <= Math.round(len * 0.4))
                this.distributionCategory.push('0.4');
              else if (x <= Math.round(len * 0.6))
                this.distributionCategory.push('0.6');
              else if (x <= Math.round(len * 0.8))
                this.distributionCategory.push('0.8');
              else if (x <= len - 1 && x <= len)
                this.distributionCategory.push('1');
              else this.distributionCategory.push('');
            }

            this.distRisk.category = this.distributionCategory;

            this.distRisk.year = year;
          });
      });
    },

    async getSectorRiskDistribution(tickerId, year, exchange_countryID) {
      if (!year || !exchange_countryID || !tickerId) {
        this.sectorRiskChart.x = [];
        this.sectorRiskChart.y = [];
        this.distSectorRisk = {
          year: 0,
          ticker: {},
          chart: {},
          point: 0,
          category: []
        };
        return;
      }

      this.$nextTick(() => {
        axios
          .post('/dashboard/sectorDistribution', {
            tickerId,
            year,
            exchangeCountryId: exchange_countryID
          })
          .then(async (response) => {
            let data = response.data || [];
            this.sectorRiskChart.x = [];
            this.sectorRiskChart.y = [];
            for (let i = 0; i < data.length; i++) {
              this.sectorRiskChart.x.push(data[i].x);
              this.sectorRiskChart.y.push(data[i].y);
            }

            this.distSectorRisk.chart = this.sectorRiskChart;

            this.distributionPointSector =
              this.ticker.finalScoreNotRounded == null
                ? -1
                : data.length * this.ticker.finalScoreNotRounded;

            this.distSectorRisk.point = this.distributionPointSector;

            this.distributionCategorySector = [];
            const len = Math.round(data.length);

            for (let x = 0; x <= len; x++) {
              if (x == 0) this.distributionCategorySector.push('0.0');
              else if (x <= Math.round(len * 0.2))
                this.distributionCategorySector.push('0.2');
              else if (x <= Math.round(len * 0.4))
                this.distributionCategorySector.push('0.4');
              else if (x <= Math.round(len * 0.6))
                this.distributionCategorySector.push('0.6');
              else if (x <= Math.round(len * 0.8))
                this.distributionCategorySector.push('0.8');
              else if (x <= len - 1 && x <= len)
                this.distributionCategorySector.push('1');
              else this.distributionCategorySector.push('');
            }

            this.distSectorRisk.category = this.distributionCategorySector;

            this.distSectorRisk.year = year;
          });
      });
    },

    async getRiskScoreHistory(tickerId, exchange_countryID) {
      axios
        .post('/dashboard/riskHistory', {
          tickerId,
          exchangeCountryId: exchange_countryID
        })
        .then((response) => {
          let data = response.data;
          this.riskHistoryData = response.data;
          this.historyChart.year = [];
          this.historyChart.score = [];
          this.historyChart.average = [];
          for (let i = 0; i < data.length; i++) {
            this.historyChart.year.push(data[i].year);
            this.historyChart.score.push(
              data[i].score == null ? 0 : data[i].score
            );
            this.historyChart.average.push(
              data[i].average == null ? 0 : data[i].average
            );
          }
        });
    },

    async getTickerDetail(tickerId, year, exchange_countryID) {
      axios
        .post('/dashboard/tickerDetail', {
          tickerId,
          year,
          exchangeCountryId: exchange_countryID
        })
        .then((response) => {
          let res = response.data;
          this.ticker.name = res.name == null ? 'N/A' : res.name;
          this.ticker.status =
            res.trading_status == null ? 'N/A' : '(' + res.trading_status + ')';
          this.ticker.industryGroup =
            res.industry_group_name == null ? 'N/A' : res.industry_group_name;
          this.ticker.sector_name =
            res.sector_name == null ? 'N/A' : res.sector_name;
          this.ticker.mv = res.MV == null ? null : res.MV;
          this.ticker.telPessimistic =
            res.total_expected_loss_pessimistic == null
              ? null
              : res.total_expected_loss_pessimistic;
          this.ticker.telOptimistic =
            res.total_expected_loss_optimistic == null
              ? null
              : res.total_expected_loss_optimistic;
          this.ticker.incorpCountry =
            res.incorporation_country == null
              ? 'N/A'
              : res.incorporation_country;
          this.ticker.domicileCountry =
            res.domicile_country == null ? 'N/A' : res.domicile_country;
          this.ticker.exchangeCountry =
            res.exchange_country == null ? 'N/A' : res.exchange_country;
          this.ticker.region_name =
            res.region_name == null ? 'N/A' : res.region_name;
          this.ticker.auditor =
            res.auditor_name == null ? 'N/A' : res.auditor_name;
          this.ticker.equityLoss =
            res.equity_loss == null ? null : res.equity_loss;
          this.ticker.rfpAbsolute =
            res.return_from_peak_absolute == null
              ? null
              : res.return_from_peak_absolute;
          this.ticker.rfpRelative =
            res.return_from_peak_relative == null
              ? null
              : res.return_from_peak_relative;
          this.ticker.dlPessimistic =
            res.debt_loss_pessimistic == null
              ? null
              : res.debt_loss_pessimistic;
          this.ticker.dlOptimistic =
            res.debt_loss_optimistic == null ? null : res.debt_loss_optimistic;
          this.ticker.finalScore =
            res.final_score == null ? null : res.final_score.toFixed(2);
          this.ticker.finalScoreNotRounded = res.final_score;
          this.ticker.exchange_country =
            res.exchange_country == null ? 'N/A' : res.exchange_country;
          this.ticker.region_name =
            res.region_name == null ? 'N/A' : res.region_name;
          this.ticker.isin = res.isin == null ? 'N/A' : res.isin;
          this.ticker.exchange_countryID = res.exchange_countryID
            ? res.exchange_countryID
            : this.ticker.exchange_countryID;
          this.ticker.rankPercent = res.rank_percent ? res.rank_percent : 0;
          this.ticker.rankPercentWithinSector = res.rank_percent_within_sector
            ? res.rank_percent_within_sector
            : 0;
          this.ticker.warning = res.warning;
          this.ticker.rating = res.rating;

          this.riskChart.notes = `{
            line: {
              length:180,
              width: 2,
              color: '#FF0000'
            },
            icon: {
              background: '#FF0000',
              size: 15
            },
            ${
              this.ticker.finalScore == null
                ? ''
                : `data: [{value: ${this.ticker.finalScore}}]}`
            }
          `;

          this.distSectorRisk.ticker = this.ticker;
          this.distRisk.ticker = this.ticker;

          this.getRiskDistribution(year, exchange_countryID);
          this.getSectorRiskDistribution(tickerId, year, exchange_countryID);
        });
    },
    showWatchlistDialog() {
      EventBus.$emit(
        'saveWatchlistFromDashboard',
        this.ticker.finalScoreNotRounded
      );
    },
    createReport() {
      console.log({
        isin: this.ticker.isin,
        year: this.choosenYear,
        regionId: this.ticker.exchange_countryID,
        uid: this.$store.state.principal.uid,
        email: this.$store.state.principal.email
      });
      axios
        .post('/dashboard/full-report-url', {
          isin: this.ticker.isin,
          year: this.choosenYear,
          regionId: this.ticker.exchange_countryID,
          uid: this.$store.state.principal.uid,
          email: this.$store.state.principal.email
        })
        .then((res) => {
          window.open(res.data, '_blank');
        });
    },
    getFactorReport() {
      if (this.reportValue.chartDetail.length == 0 && !this.choosenYear) {
        this.reportRestrictAccess = true;
      } else {
        axios
          .post('/dashboard/chartFactor', {
            tickerId: this.ticker.id,
            year: this.choosenYear,
            exchange_countryID: this.ticker.exchange_countryID,
            clusterType: this.reportValue.chartDetail.map((a) => a.cluster_type)
          })
          .then((response) => {
            let factorData = [];
            if (response.data && response.data.restrictAccess == true) {
              this.restrictAccess = true;
              this.clearData();
            } else if (response.data && response.data.restrictAccess == false) {
              factorData = response.data.data;
              this.reportValue.chartDetail.forEach((element) => {
                element.factor = [];
                factorData.forEach((factorData) => {
                  if (element.cluster_type == factorData.cluster_type) {
                    element.factor.push(factorData);
                  }
                });
              });
            }
          })
          .then(() => {
            this.$nextTick(() => {
              if (this.restrictAccess == false) {
                this.reportValue.choosenYear = this.choosenYear;
                this.reportValue.radialDetail = this.radialDetail;
                this.reportValue.ticker = this.ticker;
                this.reportValue.riskChart = this.distRisk.chart;
                this.reportValue.sectorRiskChart = this.distSectorRisk.chart;
                this.reportValue.historyChart = this.historyChart;
                this.reportValue.riskPoint = this.distRisk.point;
                this.reportValue.sectorRisk = this.distSectorRisk.point;
                this.reportValue.category = this.distRisk.category;
                this.reportValue.categorySector = this.distSectorRisk.category;

                let newTab = this.$router.resolve({
                  name: 'report',
                  query: { reportValue: JSON.stringify(this.reportValue) }
                });
                window.open(newTab.href);
              }
            });
          });
      }
    },
    clearData() {
      this.availableYear = [];
      this.choosenYear = null;
      this.chartDetail = [];
      this.radialDetail = [];
      this.historyChart.year = [];
      this.historyChart.score = [];
      this.historyChart.average = [];
      this.ticker.name = 'N/A';
      this.ticker.status = 'N/A';
      this.ticker.industryGroup = 'N/A';
      this.ticker.sector_name = 'N/A';
      this.ticker.mv = null;
      this.ticker.telPessimistic = null;
      this.ticker.telOptimistic = null;
      this.ticker.incorpCountry = 'N/A';
      this.ticker.domicileCountry = 'N/A';
      this.ticker.exchangeCountry = 'N/A';
      this.ticker.auditor = 'N/A';
      this.ticker.equityLoss = null;
      this.ticker.rfpAbsolute = null;
      this.ticker.rfpRelative = null;
      this.ticker.dlPessimistic = null;
      this.ticker.dlOptimistic = null;
      this.ticker.finalScore = null;
      this.ticker.exchange_country = 'N/A';
      this.ticker.region_name = 'N/A';
      this.ticker.isin = 'N/A';
      this.ticker.exchange_countryID = null;
      this.ticker.rankPercent = 0;
      this.ticker.rankPercentWithinSector = 0;
      this.ticker.warning = null;
      this.ticker.rating = null;
      this.riskChart.x = [];
      this.riskChart.y = [];
      this.distRisk = {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      };
      this.sectorRiskChart.x = [];
      this.sectorRiskChart.y = [];
      this.distSectorRisk = {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      };
    }
  }
};
</script>
